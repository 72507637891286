import { useState, useEffect } from 'react';
import {
	Radio,
	Steps,
	Divider,
	Typography,
	Flex,
	Card,
	Alert,
	Button,
} from 'antd';

import './App.css';
import CloudflareLogo from './cloudflare-logo';
function App() {
	const [ssl, setSsl] = useState('off');
	const [step, setStep] = useState(0);
	const [verify, setVerify] = useState(true);

	useEffect(() => {
		if (step <= 4) {
			setSsl('off');
		} else {
			setSsl('strict');
		}
	}, [ssl, setSsl, step, setStep]);

	const { Text, Link } = Typography;
	return (
		<div>
			<Card
				className="bg-white App container my-4  p-2 rounded small"
				style={{ maxWidth: 1000, margin: 'auto' }}
			>
				<div style={{ opacity: '0.5' }}>
					<Divider orientation="left" plain>
						HUB
					</Divider>
					<Flex gap="middle" align="start" justify="space-between">
						<Link style={{ minWidth: 102 }}>domain.tld</Link>
						<Text type="secondary">Cloudflare</Text>
						<Text> ❌</Text>
						<Text> ❌</Text>
						<Link type="dashed">Recheck</Link>
					</Flex>
					<Flex gap="middle" align="start" justify="space-between">
						<Link style={{ minWidth: 102 }}>www.domain.tld</Link>
						<Text type="secondary">Cloudflare</Text>
						<Text> ❌</Text>
						<Text> ❌</Text>
						<Link type="dashed">Recheck</Link>
					</Flex>
					<Divider orientation="left" plain>
						DNS
					</Divider>
					<Flex gap="middle" align="start" justify="space-between">
						<Text type="secondary">CNAME</Text>
						<Text type="secondary">domain.tld</Text>
						<Text type="secondary">[].tempurl.host</Text>
						<Text type="secondary">
							<CloudflareLogo /> Proxied
						</Text>
					</Flex>
					<Flex gap="middle" align="start" justify="space-between">
						<Text type="secondary">CNAME</Text>
						<Text type="secondary" style={{ minWidth: 68 }}>
							www
						</Text>
						<Text type="secondary">[].tempurl.host</Text>
						<Text type="secondary">
							<CloudflareLogo /> Proxied
						</Text>
					</Flex>
				</div>

				<Divider orientation="left" plain>
					SSL Mode
				</Divider>
				<Flex>
					<Radio.Group
						onChange={(e) => {
							setSsl(e.target.value);
						}}
						value={ssl}
						direction="horizontal"
						disabled
					>
						<Radio value={'off'}>Off (not secure)</Radio>
						<Radio value={'flexible'}>Flexible</Radio>
						<Radio value={'full'}>Full </Radio>
						<Radio value={'strict'}>Full (strict) </Radio>
					</Radio.Group>
				</Flex>

				<Divider orientation="left" plain>
					Steps
				</Divider>
				<Steps
					direction="vertical"
					size="small"
					current={step}
					onChange={(value) => {
						setStep(value);
					}}
					items={[
						{
							title: (
								<>
									Set the SSL to <b>Off</b> on Cloudflare
								</>
							),
						},
						{
							title: 'Recheck the DNS status on HUB',
							description: (
								<Text type="secondary">
									if the <Text code>www</Text> version of the domain is added in
									the HUB, recheck that too. This is important.
								</Text>
							),
						},
						{
							title: 'Did HUB verify the DNS?',
							description: (
								<>
									<Button
										onClick={(e) => {
											setVerify(true);
											setStep(step + 1);
										}}
										style={{ marginRight: 5 }}
									>
										Yes ✅
									</Button>
									<Button onClick={(e) => setVerify(false)}> No ❌</Button>
									<br />
									{!verify && (
										<>
											<Text>
												- Whitelist hosting IP <Text code> 18.219.56.14</Text>.
												It must be added under{' '}
												<Text code> Cloudflare - Security - WAF - Tools</Text>.
												<br />
												<Text type="warning">
													- Common mistake is adding the IPs under Cloudflare -
													Security - WAF - Firewall Rules.
												</Text>
												<br />
											</Text>
											- <b>Recheck</b> both the domains after whitelisting the
											IPs
										</>
									)}
								</>
							),
						},
						{
							title: 'HUB DNS is Green now',
							description: 'Allow 2/3 minutes generate the SSL on server',
						},
						{
							title: 'Recheck',
							subTitle: 'both the domains again',
							description: 'Now DNS should be RED but SSL will show GREEN',
						},
						{
							title: (
								<>
									Set the SSL to <b>Full (strict)</b> on Cloudflare
								</>
							),
						},
						{
							title: 'Recheck',
							subTitle: 'both the domains again',
							description:
								'Now all should be GREEN and primary domain can be set.',
						},
						{
							title: 'All set!!!',
						},
						{
							title: 'Still no luck!',
						},
					]}
				/>
				{step === 8 && (
					<Alert
						message="Universal SSL"
						description={
							<Text>
								If you're using a single SSL certificate, make sure you to
								enable Universal SSL. But if you're using a wildcard
								certificate, you need to disable. It's located under the{' '}
								<Text code>SSL/TLS - Edge Certificate - Universal SSL.</Text>{' '}
								<br />
								<br />
								After you've adjusted the Universal SSL setting based on your
								certificate type, you need to remove your domain from the HUB.
								Then, you can start the process over again.
								<br /> <br />
								In rare cases, you may have to regenerate the Universal SSL and
								start the process over again.
							</Text>
						}
						type="info"
					/>
				)}
			</Card>
		</div>
	);
}

export default App;
